import styled from "styled-components";
import HeaderText from "../../Text/HeaderText";

const PartnershipSection = () => {
  return (
    <Partner>
      <div className="container div">
        <PartnerContainer>
          <PartnerBox>
            <div className="part1">
              <HeaderText
                title="Partnerships"
                title1="Partnerships"
                color="#000723"
              />
              <div className="mt-lg-5"></div>
              <div className="center">
              <img src="/img/part.png" className="imgpart" alt="logo" />
              </div>
              <div className="mt-lg-5"></div>
              <p>
                We partner with good business to create awareness and implement
                the marketing communication strategy for the SKY brand in
                Nigeria by ensuring that the essence of the brand is well
                delivered and communicated to all teen girls.
              </p>
            </div>
            <div className="part2">
              <img
                src="/img/partmag.png"
                className="imgmag"
                alt="poster"
              />
            </div>
          </PartnerBox>
        </PartnerContainer>
      </div>
    </Partner>
  );
};

const PartnerBox = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    color: #030c2d;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular",Times;
    padding-right: 0px;
  }

  .part1 {
    display: flex;
    flex-direction: column;
    // background-color:red;
    width: 100%;
  }

  .part2 {
    display: flex;
    width: 100%;
    flex-direction: column;
    // background-color:green;
  }
  .imgpart {
    width: 300px;
    height: 300px;
  }
  .imgmag {
    width: 100%;
    height: 500px;
    // margin-top:80px;
  }
  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    .imgpart {
      width: 200px;
      height: 200px;
    }
    .imgmag {
      width: 400px;
      height: 300px;
      margin-top: 80px;
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    .imgmag {
      width: auto;
      height: 400px;
      margin-top: 100px;
    }
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    p {
      color: #030c2d;
      font-size: 15px;

      padding-right: 0px;
    }
    .part1 {
      display: flex;
      flex-direction: column;
      // justify-content:center;
      // align-items:center;
      // background-color:red;
      width: 100%;
    }

    .part2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .imgpart {
      width: 200px;
      height: 200px;
    }

    .imgmag {
      width: 350px;
      height: 300px;
    }
  }
  @media (max-width: 640px) {
    p {
      color: #030c2d;
      font-size: 14px;

      padding-right: 0px;
    }
    .part1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items:center;
      // width:100%;
    }

    .part2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .imgpart {
      width: 100px;
      height: 100px;
    }

    .imgmag {
      width: auto;
      height: 200px;
    }
  }
  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #030c2d;
      font-size: 15px;

      padding-right: 0px;
    }
    .part1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }

    .part2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
    .imgpart {
      width: 40%;
      height: auto;
    }

    .imgmag {
      width: 100%;
      height: auto;
    }
  }
`;
const Partner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(${process.env.PUBLIC_URL}/img/water2.png);
  background-size: contain; 
  background-repeat: no-repeat;

  background-position: center; 
  background-repeat: no-repeat;
  .imgaward {
    width: 100%;
    height: 100%;
    margin-top: 80px;
  }
  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
  }
  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
  }
  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
  }
`;
const PartnerContainer = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 768px) {
    /* For tablet-sized screens */
  }

  @media (max-width: 576px) {
    /* For mobile phone-sized screens */
    //   display:flex;
    //   flex-direction:column;
    //   justify-content:center;
    //   background-color:pink;
  }
`;

export default PartnershipSection;
