import styled from "styled-components";
import HeaderText from "../../Text/HeaderText";
import FeaturedButton from "./FeaturedButton";
import { NavLink } from "react-router-dom";

const FeaturedSection = () => {
  return (
    <Featured>
      <div className="container div">
        <HeaderText
          title="Featured Projects"
          title1="Featured"
          title2="Projects"
          color="#00D7FF"
        />
      </div>
      <FeaturedContainer>
        <div className="slider">
          <div>
            <IframeContainer>
              <iframe
                src="https://www.youtube.com/embed/HVlLMQxCZ9I?si=xM6kN0bjj6JeB5v9"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          </div>
          <div>
            <IframeContainer>
              <iframe
                src="https://www.youtube.com/embed/SEN1_j_tyuk?si=EDy8vYLIw5ba98C-"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          </div>
          <div>
            <IframeContainer>
              <iframe
                src="https://www.youtube.com/embed/Jz9IdpBbQYo?si=SypRMNfa5Gv98mIE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          </div>
        </div>
      </FeaturedContainer>
      <div className="bt">
        <NavLink to="/featured-projects" className="custom-link">
          <div className="container div">
            <FeaturedButton />
          </div>
        </NavLink>
      </div>
    </Featured>
  );
};

const IframeContainer = styled.div`
  iframe {
    width: 450px;
    height: 370px;
  }
  @media (max-width: 1920px) {
    iframe {
      width: 580px;
      height: 340px;
    }
  }
  @media (max-width: 1600px) {
    iframe {
      width: 450px;
      height: 315px;
    }
  }
  @media (max-width: 1440px) {
    iframe {
      width: 420px;
      height: 315px;
    }
  }
  @media (max-width: 1280px) {
    iframe {
      width: 373px;
      height: 250px;
    }
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    iframe {
      width: 400px;
      height: 250px;
    }
  }
  @media (max-width: 1024px) and (min-height: 1366px) {
    iframe {
      width: 285px;
      height: 200px;
    }
  }
  @media (max-width: 912px) {
    iframe {
      width: 210px;
      height: 160px;
    }
  }
  @media (max-width: 640px) {
    iframe {
      width: 300px;
      height: 200px;
    }
  }
  @media (max-width: 480px) {
    iframe {
      width: 280px;
      height: 120px;
    }
  }
`;

const Featured = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/tenbg1.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  .custom-link {
    text-decoration: none;
    color: #030c2d;
    font-family: "MavenMedium", Times;
  }
  .bt {
    /* width:190px; */
    /* width: 10%; */
  }
  @media (max-width: 1200px) {
    height: 100vh;
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
  }
  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }
  @media (max-width: 912px) {
    height: 50vh;
  }
  @media (max-width: 640px) {
    height: 50vh;
    .bt {
      width: 190px;
    }
  }
  @media (max-width: 480px) {
    height: 100vh;
    .bt {
      width: 140px;
    }
  }
`;

const FeaturedContainer = styled.div`
  .slider {
    display: flex;
    overflow-x: auto; /* Changed to overflow-x for horizontal scroll only */
    white-space: nowrap;
    gap: 2rem;
    padding: 4rem 3rem;

    /* Custom Scrollbar Styling */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #00d7ff #030c2d; /* Thumb color and track color for Firefox */

    /* For Chrome, Safari, and Edge */
    &::-webkit-scrollbar {
      height: 8px; /* Height of horizontal scrollbar */
    }
    &::-webkit-scrollbar-track {
      background: #030c2d; /* Dark track matching your color scheme */
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #00d7ff; /* Cyan thumb matching your HeaderText color */
      border-radius: 4px;
      border: 1px solid #030c2d; /* Adds a subtle border for depth */
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #00b7d4; /* Slightly darker cyan on hover */
    }
  }

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 912px) {
    .slider {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      gap: 2rem;
      padding: 3rem 3rem;

      /* Custom Scrollbar Styling */
      scrollbar-width: thin;
      scrollbar-color: #00d7ff #030c2d;

      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #030c2d;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #00d7ff;
        border-radius: 4px;
        border: 1px solid #030c2d;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #00b7d4;
      }
    }
  }

  @media (max-width: 640px) {
    .slider {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      gap: 2rem;
      padding: 1rem 3rem;

      /* Custom Scrollbar Styling */
      scrollbar-width: thin;
      scrollbar-color: #00d7ff #030c2d;

      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #030c2d;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #00d7ff;
        border-radius: 4px;
        border: 1px solid #030c2d;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #00b7d4;
      }
    }
  }

  @media (max-width: 480px) {
    .slider {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      gap: 2rem;
      padding: 1rem 3rem;
      overflow: hidden; /* No scrolling needed in column layout */
    }
  }
`;

export default FeaturedSection;