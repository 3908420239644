"use client"

import { useState, useEffect } from "react"
import styled from "styled-components"
import Navbar from "../../Navbar/Navbar"
import "./style.css"
import { Link, useNavigate } from "react-router-dom"

const Interactive = () => {
  const [hoveredImage, setHoveredImage] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    const hoverSequence = [1, 2, 3, 4, 5, 6, 7]
    let currentIndex = 0

    const hoverInterval = setInterval(() => {
      if (currentIndex < hoverSequence.length) {
        setHoveredImage(hoverSequence[currentIndex])
        currentIndex++
      } else {
        clearInterval(hoverInterval)
        setTimeout(() => {
          navigate("/home")
        }, 2000) // Navigate to home page after 4 seconds
      }
    }, 4000) // Change hover every 4 seconds

    return () => clearInterval(hoverInterval)
  }, [navigate])

  const getHoverClass = (imageNumber) => {
    return hoveredImage === imageNumber ? `imagehover${imageNumber}` : ""
  }

  return (
    <Land>
      <div className="container">
        <Navbar />
        <div className="element-home-our-story container">
          <div className={`element-i-home-our-story ${getHoverClass(hoveredImage)}`}>
            <div className="overlap-group">
              <div className="overlap">
                <div className="div">
                  <img src="img/hover1.png" className="hover1" alt="hovericon1" />
                  <img src="img/hover2.png" className="hover2" alt="hovericon2" />
                  <img src="img/hover3.png" className="hover3" alt="hovericon3" />
                  <img src="img/hover4.png" className="hover4" alt="hovericon4" />
                  <img src="img/hover5.png" className="hover5" alt="hovericon5" />
                  <img src="img/hover6.png" className="hover6" alt="hovericon6" />
                  <img src="img/hover7.png" className="hover7" alt="hovericon7" />
                </div>
                <div>
                  <img className="creative-innovation" alt="Creative innovation" src="img/icreative.png" />
                  <img className="sound" alt="Sound" src="img/isound.png" />
                  <img className="smell" alt="Smell" src="img/ismell.png" />
                  <img className="element-goal" alt="Element goal" src="img/igoal.png" />
                  <img className="two-directions" alt="Two directions" src="img/itwo.png" />
                  <img className="data" alt="Data" src="img/idata.png" />
                  <img className="idea" alt="Idea" src="img/iidea.png" />
                  <img className="technology" alt="Technology" src="img/itech.png" />
                  <img className="vector" alt="Vector" src="img/iv.png" />
                  <img className="th-advantage-people" alt="Th advantage people" src="img/ipeople.png" />
                  <Link to="/home">
                    <img src="img/continue.png" className="continue" alt="continue" />
                  </Link>
                  <img className="sight" alt="Sight" src="img/isight.png" />
                  <img className="filler" alt="Fill" src="img/ix.png" />
                  <img className="filler-2" alt="Filler" src="img/ifilter1.png" />
                  <img className="vector-3" alt="Vector" src="img/ibox.png" />
                </div>
                <div className="overlap-2">
                  <img className="individual" alt="Individual" src="img/iindividual.png" />
                  <img className="HR" alt="Hr" src="img/ihr.png" />
                  <img className="touch" alt="Touch" src="img/itouch.png" />
                  <img className="filler-3" alt="Filler" src="img/ifilter1.png" />
                </div>
                <img className="brand-management" alt="Brand management" src="img/ibrand.png" />
                <img className="finance" alt="Finance" src="img/ifinance.png" />
                <img className="tatste" alt="Taste" src="img/itatste.png" />
                <img className="vector-4" alt="Vector" src="img/ioo.png" />
                <img className="filler-4" alt="Fill" src="img/ifilter2.png" />
                <img className="filler-5" alt="Filler" src="img/io.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-home">
          <div className={`overlap-group-wrapper ${getHoverClass(hoveredImage)}m`}>
            <div className="overlap-group">
              <img src="img/hoverm1.png" className="hover1m" alt="hovericonm1" />
              <img src="img/hoverm2.png" className="hover2m" alt="hovericonm2" />
              <img src="img/hoverm3.png" className="hover3m" alt="hovericonm3" />
              <img src="img/hoverm4.png" className="hover4m" alt="hovericonm4" />
              <img src="img/hoverm5.png" className="hover5m" alt="hovericonm5" />
              <img src="img/hoverm6.png" className="hover6m" alt="hovericonm6" />
              <img src="img/hoverm7.png" className="hover7m" alt="hovericonm7" />
              <div className="overlap">
                <img className="creatives" alt="Creatives" src="img/icreative.png" />
                <img className="individual" alt="Individual" src="img/iindividual.png" />
                <img className="hr" alt="Hr" src="img/ihr.png" />
                <img className="tatste" alt="Taste" src="img/itatste.png" />
                <img className="smell" alt="Smell" src="img/ismell.png" />
                <img className="element-goal" alt="Element goal" src="img/igoal.png" />
                <img className="two-directions" alt="Two directions" src="img/itwo.png" />
                <img className="idea" alt="Idea" src="img/iidea.png" />
                <img className="technology" alt="Technology" src="img/itech.png" />
                <img className="iv" alt="Iv" src="img/Iv2.png" />
                <img className="touch" alt="Touch" src="img/touch2.png" />
                <img className="people" alt="People" src="img/people2.png" />
                <img className="sight" alt="Sight" src="img/isight.png" />
                <img className="filter" alt="Filter" src="img/ifilter1.png" />
                <img className="group" alt="Group" src="img/ifilter1.png" />
                <img className="img" alt="Filter" src="img/filt.png" />
              </div>
              <div className="overlap2">
                <img className="brand-management" alt="Brand management" src="img/ibrand.png" />
                <img className="finance" alt="Finance" src="img/ifinance.png" />
                <img className="sound" alt="Sound" src="img/sound.png" />
                <img id="dt" className="data" alt="Data" src="img/idata.png" />
              </div>
              <Link to="/home">
                <img src="img/continue.png" className="continue" alt="continue" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Land>
  )
}

const Land = styled.div`
  background-color: #030c2d;
  overflow: hidden;
  .element-i-home-our-story {
    img {
      opacity: 1;
      transition: opacity 4s ease; // Adjust the duration as needed
    }
    .continue{

    }
    .hover1,
    .hover2,
    .hover3,
    .hover4,
    .hover5,
    .hover6,
    .hover7 {
      opacity: 0;
    }
  }
  .imagehover1 {
    .technology,
    .tatste,
    .finance,
    .data,
    .filler,
    .brand-management,
    .creative-innovation,
    .vector,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .two-directions,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover1 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover2 {
    .technology,
    .tatste,
    .finance,
    .data,
    .element-goal,
    .filler,
    .brand-management,
    .creative-innovation,
    .vector,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover2 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover3 {
    .tatste,
    .finance,
    .element-goal,
    .filler,
    .brand-management,
    .creative-innovation,
    .vector,
    .two-directions,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .filler-4,
    .touch,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover3 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover4 {
    .tatste,
    .element-goal,
    .filler,
    .vector,
    .two-directions,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .sound,
    .sight,
    .filler-3,
    .filler-5,
    .individual,
    .data,
    .technology,
    .idea,
    .vector-3,
    .filler-4,
    .touch,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover4 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover5 {
    .element-goal,
    .brand-management,
    .creative-innovation,
    .HR,
    .finance,
    .filler,
    .vector,
    .two-directions,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .filler-3,
    .filler-5,
    .individual,
    .data,
    .technology,
    .idea,
    .vector-3,
    .filler-4,
    .vector-2 {
      opacity: 0.1;
    }
    .hover5 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover6 {
    .technology,
    .element-goal,
    .tatste,
    .finance,
    .data,
    .filler,
    .brand-management,
    .creative-innovation,
    .th-advantage-people,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .two-directions,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }
    .hover6 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  .imagehover7 {
    .technology,
    .vector,
    .element-goal,
    .tatste,
    .finance,
    .data,
    .filler,
    .brand-management,
    .creative-innovation,
    .filler-2,
    .vector-4,
    .technology,
    .sound,
    .sight,
    .filler-3,
    .HR,
    .filler-5,
    .individual,
    .vector-3,
    .idea,
    .filler-4,
    .touch,
    .two-directions,
    .smell,
    .vector-2 {
      opacity: 0.1;
    }

    .hover7 {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }

  @media (max-width: 640px) {
    .overlap-group-wrapper {
      img {
        opacity: 1;
        transition: opacity 4s ease; // Adjust the duration as needed
      }
      .hover1m,
      .hover2m,
      .hover3m,
      .hover4m,
      .hover5m,
      .hover6m,
      .hover7m {
        opacity: 0;
      }
    }
    .imagehover1m {
      .creatives,
      .two-directions,
      .individual,
      .hr,
      .tatste,
      .smell,
      .idea,
      .technology,
      .iv,
      .touch,
      .people,
      .sight,
      .filter,
      .group,
      .img,
      .brand-management,
      .finance,
      .sound,
      .data {
        opacity: 0.1;
      }

      .hover1m {
        opacity: 1;
      }
      img {
        transition: opacity 2s ease;
      }
    }
  //one end

  .imagehover2m {
    .creatives,
    .element-goal,
    .individual,
    .hr,
    .tatste,
    .smell,
    .idea,
    .technology,
    .iv,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .brand-management,
    .finance,
    .sound,
    .data {
      opacity: 0.1;
    }
    .hover2m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  //twoend

  .imagehover3m {
    .creatives,
    .two-directions,
    .individual,
    .element-goal,
    .hr,
    .tatste,
    .smell,
    .iv,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .brand-management,
    .finance,
    .sound,
    #datas .data {
      opacity: 0.1;
    }
    .hover3m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  // thirdend
  .imagehover4m {
    .idea,
    .technology,
    .element-goal,
    .individual,
    .two-directions,
    .tatste,
    .smell,
    .iv,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .sound,
    .data {
      opacity: 0.1;
    }
    .hover4m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  // fourthend
  .imagehover5m {
    .creatives,
    .element-goal,
    .two-directions,
    .individual,
    .hr,
    .idea,
    .technology,
    .iv,
    .people,
    .filter,
    .group,
    .img,
    .brand-management,
    .finance,
    .data {
      opacity: 0.1;
    }
    .hover5m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  // fifthend

  .imagehover6m {
    .idea,
    .technology,
    .element-goal,
    .individual,
    .two-directions,
    .tatste,
    .smell,
    .touch,
    .people,
    .sight,
    .filter,
    .group,
    .img,
    .sound,
    .creatives,
    .individual,
    .hr,
    .touch,
    .img,
    .brand-management,
    .finance,
    .data {
      opacity: 0.1;
    }
    .hover6m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
  //  sixth end
  .imagehover7m {
    .idea,
    .technology,
    .element-goal,
    .individual,
    .two-directions,
    .tatste,
    .smell,
    .touch,
    .sight,
    .filter,
    .group,
    .img,
    .sound,
    .creatives,
    .individual,
    .hr,
    .iv,
    .touch,
    .img,
    .brand-management,
    .finance,
    .data {
      opacity: 0.1;
    }
    .hover7m {
      opacity: 1;
    }
    img {
      transition: opacity 2s ease;
    }
  }
}

  //end
`;
export default Interactive;
