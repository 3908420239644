import styled from "styled-components";

const DreamersContent = () => {
  return (
    <DreamCont>
      <div className="container div py-5">
        <div className="layer1">
          <div>
            <p className="mix">A mix of Experience and Youth</p>
          </div>
          <div>
            <li>
              Over 60 years of combined experience, we
              <br /> are the team you should work with
            </li>
            <li className="mobile">
              Over 60 years of combined experience, we are the team you should
              work with
            </li>
          </div>
        </div>
        <div className="wrapper-dreamers">
          
          <div className="left-dreamers">
            <img
              src="/img/taiwo.svg"
              className="laptop"
              alt="dreamers-taiwo"
            />
            <img src="/img/taiwo2.svg" className="mobile" alt="dreamers-taiwo" />

            <HoverContentBig2 className="content px-3">
              <div>
                <img
                  src="/img/dreammaker.png"
                  className="icon"
                  alt="icon-dreammaker"
                />
              </div>

              <div className="flex mt-2">
                <h1 className="h1normal">Taiwo</h1>
                <h1 className="h1colored">Agboola</h1>
              </div>
              <span className="title">Chief Dream Maker</span>
              <h6 className="role">CEO</h6>
              <div className="scrollable-details">
                <p className="details">
                  With over 20+ years in the industry, Taiwo possesses an
                  unbridled knowledge of goings-on in business, local and global
                  economies, all of which have made him a formidable and savvy
                  market analyst.
                </p>
              </div>
            </HoverContentBig2>
          </div>

          <div className="right-dreamers">
            <div className="dreamers-grid">
              <ImageWrapper className="grid-item im3">
                <img
                  src="/img/benedicta.svg"
                  className="small"
                  alt="dreamers-benedicta"
                />
                <HoverContentSmall className="content px-3 py-2">
                  <img
                    src="/img/rainholder.png"
                    className="icon"
                    alt="icon-rainholder"
                  />
                  <div className="flex mt-2">
                    <h1 className="h1normal">Benedicta</h1>
                    <h1 className="h1colored">Ogbian</h1>
                  </div>
                  <span className="title">Lead, Rain Holder</span>
                  <h6 className="role">Team Lead, Brand Management</h6>
                  <div className="scrollable-details-small">
                    <p className="details">
                      With over 10 years of experience in marketing and
                      advertising, Benedicta specializes in brand management,
                      experiential marketing, sales, and trade marketing. A
                      member of the Chartered Institute of Marketing (UK), her
                      expertise has been key in driving the success of several
                      local and international brands, backed by her solid
                      administrative skills and deep industry insight.
                    </p>
                  </div>
                </HoverContentSmall>
              </ImageWrapper>
              <ImageWrapper className="grid-item im4">
                <img src="/img/tobi.svg" className="big" alt="dreamers-tobi" />
                <HoverContentBig className="content px-3">
                  <img
                    src="/img/craftsman.png"
                    className="icon"
                    alt="icon-craftsman"
                  />
                  <div className="flex mt-2">
                    <h1 className="h1normal">Oluwatobi</h1>
                    <h1 className="h1colored">Beckley</h1>
                  </div>
                  <span className="title">Craftsman In Chief</span>
                  <h6 className="role">Creative & Innovations Director</h6>
                  <div className="scrollable-details">
                    <p className="details">
                      Tobi Beckley-Williams is a seasoned creative with over 12
                      years of experience, and has launched impactful campaigns
                      for global brands like Shell, Spotify, Heineken, and
                      Nestle. A Grand Jury member for the New York Festivals,
                      she was among the first West African creatives featured on
                      Luzer's Archive and Ads of the World. Holding a law degree
                      and training from Cambridge and Miami Ad School, Tobi is
                      the Creative Director at 7even Interactive Nigeria, with a
                      background in top agencies like Insight Publicis and Insel
                      Communications Ghana.
                    </p>
                  </div>
                </HoverContentBig>
              </ImageWrapper>
              <ImageWrapper className="grid-item im5">
                <img
                  src="/img/bisi.svg"
                  className="big"
                  alt="dreamers-adebisi"
                />
                <HoverContentBig className="content px-3">
                  <img
                    src="/img/bounty.png"
                    className="icon"
                    alt="icon-bounty"
                  />
                  <div className="flex mt-2">
                    <h1 className="h1normal">Adebisi</h1>
                    <h1 className="h1colored">Ajayi</h1>
                  </div>
                  <span className="title">Bounty Handler In Chief</span>
                  <h6 className="role">Finance & Admin Director</h6>
                  <div className="scrollable-details">
                    <p className="details">
                      Seasoned Professional Accountant with 13+ years in
                      Finance, Tax and Financial Reporting and 9+ years in
                      Advertising. An Associate Member of Institute of Chartered
                      Accountants of Nigeria and Member of Nigeria Institute of
                      Management, who formerly worked in the health sector. He
                      is the money man and doesn’t laugh when money is involved.
                    </p>
                  </div>
                </HoverContentBig>
              </ImageWrapper>
              <ImageWrapper className="grid-item im6">
                <img
                  src="/img/gloria.svg"
                  className="small"
                  alt="dreamers-gloria"
                />
                <HoverContentSmall className="content px-3 py-2">
                  <img
                    src="/img/rainholder.png"
                    className="icon"
                    alt="icon-rainholder"
                  />
                  <div className="flex mt-2">
                    <h1 className="h1normal">Gloria</h1>
                    <h1 className="h1colored">Emeh</h1>
                  </div>
                  <span className="title">Lead, Rain Holder</span>
                  <h6 className="role">Team Lead, Brand Management</h6>
                  <div className="scrollable-details-small">
                    <p className="details">
                      Started her career in IMC 2013. Gloria has extensive
                      experience in Marketing and Advertising ranging from Above
                      the Line (TV, Print, Outdoor), Direct Marketing,
                      Promotional and Digital. Consistently grew to positions of
                      increased responsibility..
                    </p>
                  </div>
                </HoverContentSmall>
              </ImageWrapper>
              <ImageWrapper className="grid-item im7">
                <img
                  src="/img/samuel.svg"
                  className="small"
                  alt="dreamers-samuel"
                />
                <HoverContentSmall className="content px-3 py-2">
                  <img
                    src="/img/rainmaker.png"
                    className="icon"
                    alt="icon-rainmaker"
                  />
                  <div className="flex mt-2">
                    <h1 className="h1normal">Samuel</h1>
                    <h1 className="h1colored">Obasi</h1>
                  </div>
                  <span className="title">Lead, Rain Maker</span>
                  <h6 className="role">Team Lead, Strategy</h6>
                  <div className="scrollable-details-small">
                    <p className="details">
                      Obasi Samuel is a seasoned marketing communications and
                      brand design expert with ten professional experience in
                      executing consumer research, IMC campaign and channels
                      development strategies, for brands across a broad spectra
                      of sectors: FMCGs, Telcos, Fintech, Oil and gas, and
                      financial services.
                    </p>
                  </div>
                </HoverContentSmall>
              </ImageWrapper>
              <ImageWrapper className="grid-item im8">
                <img
                  src="/img/modupe.svg"
                  className="big"
                  alt="dreamers-modupe"
                />
                <HoverContentBig className="content px-3">
                  <img
                    src="/img/scribe.png"
                    className="icon"
                    alt="icon-scribe"
                  />
                  <div className="flex mt-2">
                    <h1 className="h1normal">Modupe</h1>
                    <h1 className="h1colored">Popoola</h1>
                  </div>
                  <span className="title">Lead, Scribe</span>
                  <h6 className="role">Team Lead, Copywriting</h6>
                  <div className="scrollable-details">
                    <p className="details">
                      With more than 10 years experience, Modupe Popoola is an
                      enthusiastic Advertising and Public Relations...
                    </p>
                  </div>
                </HoverContentBig>
              </ImageWrapper>
            </div>
          </div>
        </div>
      </div>
    </DreamCont>
  );
};

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const HoverContentSmall = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/rect.png);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  .icon {
    width: 25px;
    height: 30px;
  }
  &:hover {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
  }
  .h1normal {
    color: white;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.96px;
    text-transform: capitalize;
    font-family: "MarkPro", sans-serif;
  }
  .h1colored {
    color: #01eaff;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.96px;
    text-transform: capitalize;
    font-family: "MarkPro", sans-serif;
  }
  .title {
    color: #01eaff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    /* font-family: "Maven Pro", sans-serif; */
  }
  .role {
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    // line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular", Times;
  }
  .details {
    color: #01eaff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular", Times;
  }
  .scrollable-details-small {
    max-height: 100px; /* Adjust the height as needed */
    overflow: hidden; /* Hide the scrollbar */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer */
    scrollbar-width: none; /* Hide the scrollbar for Firefox and Edge */
  }

  .scrollable-details-small::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
  }

  .scrollable-details-small:hover {
    overflow: auto; /* Show the scrollbar on hover */
  }

  .flex {
    display: flex;
    gap: 0.5rem;
  }
  /* @media (max-width: 1280px) {
    width: 340px;
    height: 100%;
  } */
  @media (max-width: 1300px) {
    width: 340px;
    height: 100%;
  }
  @media (max-width: 912px) {
    width: 340px;
    height: 100%;
  }
  @media (max-width: 640px) {
    width: 350px;
    height: 100%;
  }
  @media (max-width: 480px) {
    width: 312px;
    height: 100%;
  }
`;
const HoverContentBig = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/rect.png);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  .icon {
    width: 30px;
    height: 30px;
  }
  &:hover {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
  }

  .h1normal {
    color: white;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.96px;
    text-transform: capitalize;
    font-family: "MarkPro", sans-serif;
  }

  .h1colored {
    color: #01eaff;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.96px;
    text-transform: capitalize;
    font-family: "MarkPro", sans-serif;
  }
  .title {
    color: #01eaff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MarkPro", sans-serif;
  }
  .role {
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    // line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular", Times;
  }
  .details {
    color: #01eaff;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular", Times;
  }
  .scrollable-details {
    max-height: 180px !important; /* Adjust the height as needed */
    overflow: hidden; /* Hide the scrollbar */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer */
    scrollbar-width: none; /* Hide the scrollbar for Firefox and Edge */
  }

  .scrollable-details::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
  }

  .scrollable-details:hover {
    overflow: auto; /* Show the scrollbar on hover */
  }

  .flex {
    display: flex;
    gap: 0.5rem;
  }
  @media (max-width: 1300px) {
    width: 340px;
    height: 100%;
  }
  @media (max-width: 912px) {
    width: 340px;
    height: 100%;
  }
  @media (max-width: 640px) {
    width: 350px;
    height: 100%;
  }
  @media (max-width: 480px) {
    width: 312px;
    height: 100%;
  }
`;

const HoverContentBig2 = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/rect.png);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 100px;
  width: 296px;
  height: 712px;
  opacity: 0;

  .icon {
    width: 30px !important;
    height: 30px !important;
  }

  &:hover {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
  }

  .h1normal {
    color: white;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.96px;
    text-transform: capitalize;
    font-family: "MarkPro", sans-serif;
  }

  .h1colored {
    color: #01eaff;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.96px;
    text-transform: capitalize;
    font-family: "MarkPro", sans-serif;
  }
  .title {
    color: #01eaff;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MarkPro", sans-serif;
  }
  .role {
    color: white;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    // line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular", Times;
  }
  .details {
    color: #01eaff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.3px;
    font-family: "MavenRegular", Times;
  }

  .flex {
    display: flex;
    gap: 0.5rem;
  }

  @media (max-width: 1300px) {
    background-image: url(${process.env.PUBLIC_URL}/img/rect.png);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    width: 350px;
    height: 400px;
    top: 150px;
    left: auto;

    .icon {
      width: 30px;
      height: 30px;
    }

    &:hover {
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
    }

    .h1normal {
      color: white;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.96px;
      text-transform: capitalize;
      font-family: "MarkPro", sans-serif;
    }

    .h1colored {
      color: #01eaff;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.96px;
      text-transform: capitalize;
      font-family: "MarkPro", sans-serif;
    }
    .title {
      color: #01eaff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      font-family: "MarkPro", sans-serif;
    }
    .role {
      color: white;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      // line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      font-family: "MavenRegular", Times;
    }
    .details {
      color: #01eaff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      font-family: "MavenRegular", Times;
    }
    .scrollable-details {
      max-height: 180px !important; /* Adjust the height as needed */
      overflow: hidden; /* Hide the scrollbar */
      -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer */
      scrollbar-width: none; /* Hide the scrollbar for Firefox and Edge */
    }

    .scrollable-details::-webkit-scrollbar {
      width: 0; /* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
    }

    .scrollable-details:hover {
      overflow: auto; /* Show the scrollbar on hover */
    }

    .flex {
      display: flex;
      gap: 0.5rem;
    }
  }
  @media (max-width: 912px) {
    background-image: url(${process.env.PUBLIC_URL}/img/rect.png);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 312px;
    height: 400px;
    opacity: 0;
    width: 350px;
    height: 400px;
    top: 125px;
    left: auto;

    .icon {
      width: 30px;
      height: 30px;
    }

    &:hover {
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
    }

    .h1normal {
      color: white;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.96px;
      text-transform: capitalize;
      font-family: "MarkPro", sans-serif;
    }

    .h1colored {
      color: #01eaff;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.96px;
      text-transform: capitalize;
      font-family: "MarkPro", sans-serif;
    }
    .title {
      color: #01eaff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      font-family: "MarkPro", sans-serif;
    }
    .role {
      color: white;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      // line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      font-family: "MavenRegular", Times;
    }
    .details {
      color: #01eaff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
      font-family: "MavenRegular", Times;
    }
    .scrollable-details {
      max-height: 180px !important; /* Adjust the height as needed */
      overflow: hidden; /* Hide the scrollbar */
      -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer */
      scrollbar-width: none; /* Hide the scrollbar for Firefox and Edge */
    }

    .scrollable-details::-webkit-scrollbar {
      width: 0; /* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
    }

    .scrollable-details:hover {
      overflow: auto; /* Show the scrollbar on hover */
    }

    .flex {
      display: flex;
      gap: 0.5rem;
    }
  }
  @media (max-width: 640px) {
    width: 350px;
    height: 400px;
    top: 140px;
    left: auto;
  }

  @media (max-width: 480px) {
    width: 312px;
    height: 400px;
    top: 150px;
    left: auto;
    /* left: 0; */
  }

  @media (max-width: 435px) {
    width: 312px;
    height: 400px;
    top: 185px;
    left: auto;
    margin: auto;
  }
  @media (max-width: 420px) {
    width: 312px;
    height: 400px;
    top: 185px;
    left: auto;
    margin: auto;
  }
  @media (max-width: 350px) {
    width: 300px;
    height: 400px;
    top: 240px;
    left: 10px;
    margin: auto;
  }
`;

const DreamCont = styled.div`
  cursor: pointer;
  background-color: white;
  height: auto;
  position: relative;
  overflow: hidden;

  .wrapper-dreamers {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    max-width: 100%;
    & .right-dreamers {
      width: 75%;
    }
    & .left-dreamers {
      width: 25%;
      height: auto;
      padding-right: 15px;

      & img {
        /* width: 312px; */
        width: auto;
        height: auto;
        max-height: 715px;
        object-fit: cover;
      }
      & .mobile {
        display: none;
      }
    }
  }
  .big {
    width: 312px;
    height: 400px;
  }
  .small {
    width: 312px;
    height: 300px;
  }

  .mobile {
    display: none;
  }

  .dreamers-grid {
    columns: 3 312px;
    gap: 15px;
  }

  .im1 {
    // background-color:red;
    width: 312px;
    position: relative;
    object-fit: cover;
  }

  .im2 {
    // background-color:green;
    width: 312px;
    margin-top: 15px;
    position: relative;
    object-fit: cover;
  }
  .im3 {
    position: relative;
    width: 312px;
    object-fit: cover;
  }
  .im4 {
    position: relative;
    width: 312px;
    margin-top: 15px;
    object-fit: cover;
  }
  .im5 {
    position: relative;
    width: 312px;
  }
  .im6 {
    position: relative;
    width: 312px;
    margin-top: 15px;
    object-fit: cover;
  }
  .im7 {
    position: relative;
    width: 312px;
    object-fit: cover;
  }
  .im8 {
    position: relative;
    width: 312px;
    margin-top: 15px;
    object-fit: cover;
  }
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #000723;
    list-style-type: none;
    font-family: "MavenRegular", Times;
  }

  .mix {
    color: #001033;
    font-family: "MarkPro", sans-serif;
    font-size: 45px;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 125% */
    letter-spacing: -0.6px;
  }

  .layer1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 1300px) {
    .wrapper-dreamers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      & .right-dreamers {
        width: 100%;
      }
      & .left-dreamers {
        width: 100%;
        height: 400px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          width: 350px;
          height: 400px;
          margin: auto;
        }
        & .laptop {
          display: none;
        }
        & .mobile {
          display: block;
        }
      }
    }
    .big {
      width: 100%;
      height: 100%;
    }

    .small {
      width: 100%;
      height: 100%;
    }
    .im1,
    .im2,
    .im3,
    .im4,
    .im5,
    .im6,
    .im7,
    .im8 {
      width: 340px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .im7,
    .im3 {
      margin-top: 15px;
    }
    .dreamers-grid {
      columns: 3 312px;
      margin-top: 20px;
    }
    .layer1 {
      display: flex;
      /* flex-direction: column; */
      /* justify-content: center; */
      /* align-items: center; */
    }

    .mobile {
      display: block;
      font-size: 18px;
    }
    .mix {
      font-size: 30px;
    }
    li {
      display: none;
    }
  }
  @media (max-width: 912px) {
    .wrapper-dreamers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      & .right-dreamers {
        width: 100%;
      }
      & .left-dreamers {
        width: 100%;
        height: 400px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          width: 350px;
          height: 400px;
          margin: auto;
        }
        & .laptop {
          display: none;
        }
        & .mobile {
          display: block;
        }
      }
    }
    .big {
      width: 100%;
      height: 100%;
    }

    .small {
      width: 100%;
      height: 100%;
    }
    .im1,
    .im2,
    .im3,
    .im4,
    .im5,
    .im6,
    .im7,
    .im8 {
      width: 340px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .im7,
    .im3 {
      margin-top: 15px;
    }
    .dreamers-grid {
      columns: 3 312px;
      margin-top: 20px;
    }
    .layer1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .mobile {
      display: block;
      font-size: 18px;
    }
    .mix {
      font-size: 30px;
    }
    li {
      display: none;
    }
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    .wrapper-dreamers {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      & .right-dreamers {
        width: 100%;
      }
      & .left-dreamers {
        width: 100%;
        height: auto;
        padding-right: 5px;

        & img {
          width: 312px; /* Ensure the image takes the full width of its container */
          height: auto;
          max-height: 715px;
          object-fit: cover;
        }
        & .mobile {
          display: none;
        }
      }
    }
    .layer1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    li {
      font-size: 16px;
    }

    .mix {
      font-size: 37px;
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    .wrapper-dreamers {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      & .right-dreamers {
        width: 100%;
      }
      & .left-dreamers {
        width: 100%;
        height: auto;
        padding-right: 5px;
        & .mobile {
          display: block;
          margin: auto;
        }
        & .laptop {
          display: none;
        }
      }
    }
    .dreamers-grid {
      columns: 2 auto !important;
      margin-top: 20px;
    }
    .layer1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .im1,
    .im2,
    .im3,
    .im4,
    .im5,
    .im6,
    .im7,
    .im8 {
      width: 312px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .im7,
    .im3,
    .im5 {
      margin-top: 15px;
    }
    .mobile {
      display: block;
      font-size: 18px;
    }
    .mix {
      font-size: 30px;
    }
    li {
      display: none;
    }
  }

  @media (max-width: 912px) {
    .wrapper-dreamers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      & .right-dreamers {
        width: 100%;
      }
      & .left-dreamers {
        width: 100%;
        height: 400px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          width: 350px;
          height: 400px;
          margin: auto;
        }
        & .laptop {
          display: none;
        }
        & .mobile {
          display: block;
        }
      }
    }
    .big {
      width: 100%;
      height: 100%;
    }

    .small {
      width: 100%;
      height: 100%;
    }
    .im1,
    .im2,
    .im3,
    .im4,
    .im5,
    .im6,
    .im7,
    .im8 {
      width: 340px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .im7,
    .im3 {
      margin-top: 15px;
    }
    .dreamers-grid {
      columns: 2 312px;
      margin-top: 20px;
    }
    .layer1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .mobile {
      display: block;
      font-size: 18px;
    }
    .mix {
      font-size: 30px;
    }
    li {
      display: none;
    }
  }

  @media (max-width: 640px) {
    .wrapper-dreamers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      & .right-dreamers {
        width: 100%;
      }
      & .left-dreamers {
        width: 100%;
        height: 400px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          width: 350px;
          height: 100%;
          margin: auto;
        }
        & .laptop {
          display: none;
        }
        & .mobile {
          display: block;
        }
      }
    }
    .layer1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .im3,
    .im5,
    .im7 {
      margin-top: 15px;
    }

    .dreamers-grid {
      margin-top: 10px;
    }
    .big {
      width: 100%;
      height: 100%;
    }

    .small {
      width: 100%;
      height: 100%;
    }
    .im1,
    .im2,
    .im3,
    .im4,
    .im5,
    .im6,
    .im7,
    .im8 {
      width: 350px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .mobile {
      display: block;
      font-size: 15px;
    }
    li {
      display: none;
    }

    .mix {
      font-size: 30px;
    }
  }

  @media (max-width: 480px) {
    .wrapper-dreamers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      & .right-dreamers {
        width: 100%;
      }
      & .left-dreamers {
        width: 312px;
        height: 400px;
        padding-left: 15px;

        & img {
          width: 312px; /* Ensure the image takes the full width of its container */
          height: 100%;
          object-fit: cover;
        }
        & .laptop {
          display: none;
        }
        & .mobile {
          display: block;
        }
      }
    }
    .im3,
    .im5,
    .im7 {
      margin-top: 15px;
    }
    .dreamers-grid {
      margin-top: 10px;
    }
    .big {
      width: 100%;
      height: 100%;
    }

    .small {
      width: 100%;
      height: 100%;
    }
    .im1,
    .im2,
    .im3,
    .im4,
    .im5,
    .im6,
    .im7,
    .im8 {
      width: 312px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .layer1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .mobile {
      display: block;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .mix {
      font-size: 22px;
    }
    li {
      display: none;
    }
  }
`;

export default DreamersContent;
