import styled from "styled-components";
// import bgfooter from "../../../../public/img/bgfooter.png"
import HeaderText from "../../Text/HeaderText";
const OurClientsSection = () => {
  return (
    <Clients>
      <div className="container div">
        <HeaderText
          title="Clients We Work For"
          title1="Clients"
          title2="We Work For"
          color="#000723"
        />
        <div className="images">
          <img src="/img/grand.png" alt="grand" />
          <img src="/img/hero.png" alt="hero" />
          <img src="/img/9mobile.png" alt="9mobile" />
          <img src="/img/wildturkey.png" alt="wildturkey" />
        </div>
      </div>
    </Clients>
  );
};

const Clients = styled.div`
   background-image: url(${process.env.PUBLIC_URL}/img/water2.png);
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;

  // justify-content:center;
  // align-items:center;
  height: 100vh;
  .div {
    margin-top: 180px;
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 160px;
    gap: 4rem;

    img {
      width: auto;
      height: 100px;
    }
  }

  @media (max-width: 1600px) {
  height: 100vh;
  .div {
    margin-top: 80px;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 160px;
    gap: 4rem;

    img {
      width: auto;
      height: 100px;
    }
  }

  }
  @media (max-width: 1280px) {
    /* For laptop-sized screens */
    .div {
      margin-top: 80px;
    }
    .images {
      margin-top: 150px;

      img {
        width: auto;
      }
    }
  }

  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
    .images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 100px;
      gap: 2rem;
      img {
        width: auto;
        height: 100px;
      }
    }
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
    .images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 100px;
      gap: 2rem;
      img {
        width: auto;
        height: 100px;
      }
    }
  }

  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    .div {
      margin-top: 150px;
    }

    .images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 100px;
      gap: 2rem;
      img {
        width: auto;
        height: 70px;
      }
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .div {
      margin-top: 10px;
    }
    .images {
      gap: 1rem;

      img {
        width: auto;
        height: 50px;
      }
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 100vh;
    .images {
      margin-top: 80px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      img {
        width: auto;
        height: 80px;
      }
    }
    .div {
      margin-top: 50px;
    }
  }
`;

export default OurClientsSection;
