"use client";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navbar from "../../Navbar/Navbar";

const ComingSoon = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const nigeriaOffset = 1 * 60; 


    const now = new Date();
    const nowUTC = now.getTime() + now.getTimezoneOffset() * 60000;
    const nowNigeria = new Date(nowUTC + nigeriaOffset * 60000);

   
    const targetDate = new Date(nowNigeria);
    targetDate.setDate(targetDate.getDate() + ((8 - targetDate.getDay()) % 7)); 
    targetDate.setHours(0, 0, 0, 0); 

    const updateCountdown = () => {
      const now = new Date();
      const nowUTC = now.getTime() + now.getTimezoneOffset() * 60000;
      const nowNigeria = new Date(nowUTC + nigeriaOffset * 60000);
      const timeDifference = targetDate.getTime() - nowNigeria.getTime();

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      } else {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Coming>
      <Navbar />
      <div className="container div">
        <div className="border">
          <div className="inner">
            <p className="countdown">
              <span className="number">{countdown.days}</span>{" "}
              <span className="label">Days</span>
              <span className="colon">:</span>
              <span className="number">{countdown.hours}</span>{" "}
              <span className="label">Hrs</span>
              <span className="colon">:</span>
              <span className="time-group">
                <span className="number">{countdown.minutes}</span>{" "}
                <span className="label">Mins</span>
                <span className="colon">:</span>
                <span className="number">{countdown.seconds}</span>{" "}
                <span className="label">Secs</span>
              </span>
            </p>
          </div>
          <p className="roadtext">Road to 10</p>
        </div>
      </div>
    </Coming>
  );
};

const Coming = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/countdown.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .border {
    border-width: 1px;
    border-color: white;
    width: 80%;
    margin: auto;
    align-items: center;
    padding: 15px 30px 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
      width: 80%;
      padding: 0px 10px 5px 10px;
    }
  }

  .inner {
    background-color: #00d7ff;
    padding: 30px;
    margin-top: 20px;
    width: 100%;
  }

  .countdown {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;

    @media (min-width: 768px) {
      font-size: 35px;
    }
  }

  .time-group {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .number {
    color: #030c2d;
  }

  .label {
    color: #030c2d;
    font-weight: 100;
  }

  .colon {
    color: #030c2d;
  }

  .roadtext {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: #ccffcc;
    font-family: "MarkPro", sans-serif;
    margin-top: 20px;

    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 56px;
    }

    @media (max-width: 640px) {
      font-size: 30px;
    }
  }
`;

export default ComingSoon;
