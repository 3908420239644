"use client";
import React from "react";
import styled from "styled-components";
import Navbar from "../../Navbar/Navbar";

const Ten = () => {
  return (
    <TenYears>
      <Navbar />
      <div className="pdiv">
        <p className="text">
          We Use Data To Power Ideas,<br /> Enabled By Technology
        </p>
      </div>
      <FloatingImage src="/img/badge.png" alt="Floating" />
    </TenYears>
  );
};

const TenYears = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/tenbg1.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;

  .pdiv {
    margin-top: 30px;
  }

  .text {
    font-weight: 400;
    font-size: 40px;
    /* line-height: 28px; */
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: white;
    font-family: "MarkPro", sans-serif;
  }

  @media (max-width: 640px) {
    height: 100vh;
   
    .text {
      font-size: 30px; 
    }
  }

  @media (max-width: 480px) {
  background-image: url(${process.env.PUBLIC_URL}/img/tensmallbg1.svg);
    height: 100vh;
    .pdiv {
      padding: 0 40px; 
    }
    .text {
      font-size: 20px; 
    }
  }
`;

const FloatingImage = styled.img`
  position: absolute;
  bottom: 20px;
  right: 150px;
  width: 120px;
  height: auto;
  animation: bounce 4s infinite ease-in-out;

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-30px);
    }
    40% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(-15px);
    }
    80% {
      transform: translateY(0);
    }
  }
`;

export default Ten;