import React, { useState } from "react";
import styled from "styled-components";
import HeaderText from "../../Text/HeaderText";
import FooterP from "../../Text/FooterP";
import FooterButton from "./FooterButton";
import {useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

const FooterSection = ({ title, title1, title2 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleButtonClicked = () => {
    openModal();
  };

  const setBack = () => {
    setTimeout(() => {
      setAccepted(false);
      setRejected(false);
    }, 3000); // Delay in milliseconds before resetting the state
  };

  const serviceId = "service_30uxbrh";
  const templateId = "template_gbbm8g7";
  const publicKey = "CTSCzUkf6yTQjxIar";

  const onSubmit = async (values, { resetForm }) => {
    const emailjs_data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        name: values.name,
        phone: values.phone,
        email: values.email,
        message: values.message,
      },
    };
    // console.log(emailjs_data);
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        emailjs_data
      );

      setLoading(false);
      setAccepted(true);
      resetForm();
      setBack();
      // closeModal();
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setRejected(true);
      setBack()
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Footer>
      <div className="container div">
        <FooterContainer>
          <HeaderText
            title={title}
            title1={title1}
            title2={title2}
            color="white"
          />

          <div className="footerdiv">
            <FooterP color="#00D7FF" />
          </div>
          <div className="mt-4">
            <FooterButton
              onClick={handleButtonClicked}
              title="Send Us A Brief"
            />
          </div>
        </FooterContainer>
      </div>

      {isModalOpen && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={closeModal}>x</CloseButton>
            <h2>Brief Details</h2>
            <div className="form">
              <input
                type="text"
                name="name"
                placeholder="Name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <div className="error">{formik.errors.name}</div>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              <div className="error">{formik.errors.phone}</div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <div className="error">{formik.errors.email}</div>
              <textarea
                as="textarea"
                rows="4"
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                placeholder="Message"
              />
              <div className="error">{formik.errors.message}</div>

              <div className="center">
                <FooterButton
                  title={loading ? "Loading..." : "Submit"}
                  onClick={formik.handleSubmit}
                />
                {accepted && <p className="green">Form Submitted !!!</p>}
                {rejected && (
                  <p className="error2">An Error occured. Try again !!!</p>
                )}
              </div>
            </div>
          </ModalContent>
        </Modal>
      )}
    </Footer>
  );
};
// Styled Components for Modal

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #030c2d;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
  border: 0.2px solid white;

  h2 {
    /* margin-bottom: 20px; */
    color: white;
    font-family: "MarkPro", Times;
  }
  .center {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;

  }
  .form {
    display: flex;
    flex-direction: column;

    .error {
      color: red;
      text-align: left;
      margin-bottom: 5px;
      font-family: "MavenMedium", Times;
    }

    .error2 {
      color: red;
      text-align: center;
      margin-top: 10px;
      font-family: "MavenRegular", Times;
    }

    .green {
      color: green;
      text-align: center;
      margin-top: 10px;
      font-family: "MavenRegular", Times;
    }
    input,
    textarea,
    select {
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      font-family: "MavenRegular", sans-serif;
      /* color: #030C2D; */
    }
  }
  @media (max-width: 540px) {
    /* background: wh; */
    padding: 10px;
    border-radius: 5px;
    width: 80%;
    text-align: center;
    position: relative;
    .error {
      text-align: left;
      font-size: 12px;
    }
    .error2 {
      font-size: 12px;
    }
    .green {
      font-size: 12px;
    }
    .form {
      display: flex;
      flex-direction: column;

      input,
      textarea,
      select {
        margin-bottom: 5px;
        padding: 5px 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 15px;
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  color: white;
  font-family: "MavenMedium", Times;
  @media (max-width: 540px) {
  font-size: 25px;

  }
`;

const FooterContainer = styled.div`
  margin-top: 100px;
  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 768px) {
    /* For tablet-sized screens */
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    margin-top: 0px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(${process.env.PUBLIC_URL}/img/water5.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .footerdiv {
    // background-color:yellow;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .div {
    margin-top: 60px;
  }
  @media (max-width: 1200px) {
    /* For laptop-sized screens */
    height: 100vh;
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
    .div {
      margin-top: -50px;
    }
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .div {
      margin-top: -50px !important;
    }
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 50vh;
    .div {
      margin: auto 0 !important;
    }
  }
`;

export default FooterSection;
