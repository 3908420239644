import styled from "styled-components";
import HeaderText from "../../Text/HeaderText";

const AwardSection = () => {
  return (
    <Award>
      <div className="container div">
        <AwardContainer>
          <HeaderText title="Awards" title1="Awards" color="white" />
          <img src="/img/award.png" className="imgaward" alt="award" />
        </AwardContainer>
      </div>
    </Award>
  );
};

const Award = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/water4.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .imgaward {
    width: 100%;
    height: 100%;
    margin-top: 80px;
  }

  @media (max-width: 1200px) {
    /* For laptop-sized screens */
    height: 100vh;
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    height: 100vh;
  }

  @media (max-width: 1024px) and (min-height: 1366px) {
    height: 50vh;
  }
  @media (max-width: 912px) {
    /* For tablet-sized screens */
    height: 50vh;
  }

  @media (max-width: 640px) {
    /* For mobile phone-sized screens */
    height: 50vh;
  }

  @media (max-width: 480px) {
    /* For mobile phone-sized screens */
    height: 50vh;
  }
`;

const AwardContainer = styled.div`
  @media (max-width: 1200px) {
    /* For laptop-sized screens */
  }

  @media (max-width: 768px) {
    /* For tablet-sized screens */
  }

  @media (max-width: 576px) {
    /* For mobile phone-sized screens */
  }
`;

export default AwardSection;
