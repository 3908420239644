import { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import gsap from "gsap";

const Navbar = (props) => {
  const logoRef = useRef(null);
  const toggleRef = useRef(null);
  const rightRef = useRef(null);
  const menuRef = useRef(null);
  const barOne = useRef(null);
  const [active, setActive] = useState(false);
  const isMounted = useRef(null);




const toggleMenu = () => {
  const tl = gsap.timeline();
  if (!active) {
    tl.to(menuRef.current, {
      display: "block",
      x: "0%", // Slide in
      ease: "Expo.easeInOut",
      duration: 1,
    });
  } else {
    tl.to(menuRef.current, {
      x: "100%", // Slide out
      ease: "Expo.easeInOut",
      duration: 1.8,
      onComplete: () => {
        menuRef.current.style.display = "none"; // Hide the menu after sliding out
      },
    });
  }
  setActive(!active);
};


  return (
    <div ref={isMounted}>
      <Nav style={{ backgroundColor: `${props.bgColor}` }}>
        <Container>
          <header className="nav-header">
            <Link to="/home">
              <div className="logo" ref={logoRef}>
                <Image src="/img/logo.png" alt="7even-logo"></Image>
              </div>
            </Link>
            <div ref={toggleRef}
            >
              <button
                className={`menu-trigger ${active ? "active" : ""}`}
                id="menu-icon"
                onClick={toggleMenu}

              //  onMouseEnter={toggleMenu}
              >
                <span ref={barOne}></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </header>

          <section 
            // onMouseLeave={toggleMenu} 
            // onClick={toggleMenu}
          className={`fullpage-menu`} ref={menuRef} >
            <div className="menu-bg">
              <nav className="right" ref={rightRef}>
                <ul className="main-menu">
                  <li>
                    <NavLink to="/home" 
                    // target="_blank" 
                    // rel="noopener noreferrer"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/featured-projects"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      Works
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/dreamers"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      Team
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/vtc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      #VTC
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </section>
        </Container>
      </Nav>
    </div>
  );
};
const Image = styled.img`
  width: 2rem;
`;
const Nav = styled.nav`
  margin: 0;
  padding: 2rem 0;
  
  .links {
    display: flex;
    gap: 2rem;
    position: absolute;
    right: 10%;

    a {
      text-decoration: none;
      font-size: 25px;
      color: #000;
     font-family: "MarkPro", sans-serif;

      
    }
  }

  .dreams {
    font-size: 2.2rem;
    font-weight: 500;
  }

  .mobile-nav {
    display: none;
  }

  .right {
    background-color: #00051f;
    a {
      text-decoration: none;
      color: #000723;
      font-size: 2rem;
      letter-spacing: 0.05em;
      padding: 15px 20px;
      position: relative;
     font-family: "MarkPro", sans-serif;

    }
    
  }
  .left p {
    padding: 15px 20px;
    position: relative;
    font-family: "MarkPro", sans-serif;

  }

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    padding: 2rem 2rem;
  }

  .menu-toggle {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .hamburger path {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
  }

  .fullpage-menu {
    position: fixed;
    top: 0;
    right:0;
    width: 100%;
    // height: 70vh;
    display: none;
    z-index: 3;
    margin-top:100px;
  }
  .menu-bg {
    height: 100vh;
    width: 15%;
    position: absolute;
    // left: 0;
    top: 0;
    right:0;
    display: grid;
    grid-template-columns:400px;
  }

  .menu-bg .right {
    height: 100%;
    width: 100%;
    border-radius: 60px 0px 0px 0px;
    background: #00D7FF;
    // background-image: url("/images/nav-bg.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    display: block;
    background-size: 32rem;
    // color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem;
  }

  nav {
    position: relative;
    z-index: 10;
  }

  nav ul {
    list-style-type: none;
  }
  nav li {
    overflow: hidden;
    transition: transform 300ms ease-in-out 0s;
  }
  nav li + li {
    margin-top: 20px;
  }
  nav li a,
  nav li p {
    text-decoration: none;
    font-weight: 400;
    display: inline-block;
    line-height: 1;
  }
  nav li a {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
    color: #01eaff;
    font-family: "MarkPro", sans-serif;

  }
  nav li p {
    font-size: 20px;
    color: #000;
  }

  nav li a:hover {
    color: #fff;
  }

  .menu-trigger,
  .menu-trigger span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
  }
  .menu-trigger {
    position: relative;
    width: 40px;
    height: 34px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
  }
  .menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #00d7ff;
    border-radius: 4px;
  }
  .menu-trigger span:nth-of-type(1) {
    top: 0;
  }
  .menu-trigger span:nth-of-type(2) {
    top: 10px;
  }
  .menu-trigger span:nth-of-type(3) {
    bottom: 0;
    top: 20px;
  }

  #menu-icon span:nth-of-type(1) {
    animation: menu-icon-bar01 0.75s forwards;
  }
  @keyframes menu-icon-bar01 {
    0% {
      transform: translateY(20px) rotate(45deg);
    }
    50% {
      transform: translateY(20px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  #menu-icon span:nth-of-type(2) {
    transition: all 0.25s 0.25s;
    opacity: 1;
  }
  #menu-icon span:nth-of-type(3) {
    animation: menu-icon-bar03 0.75s forwards;
  }
  @keyframes menu-icon-bar03 {
    0% {
      transform: translateY(-20px) rotate(-45deg);
    }
    50% {
      transform: translateY(-20px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  #menu-icon.active span:nth-of-type(1) {
    animation: active-menu-icon-bar01 0.75s forwards;
  }
  @keyframes active-menu-icon-bar01 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(10px) rotate(0);
    }
    100% {
      transform: translateY(10px) rotate(45deg);
    }
  }
  #menu-icon.active span:nth-of-type(2) {
    opacity: 0;
  }
  #menu-icon.active span:nth-of-type(3) {
    animation: active-menu-icon-bar03 0.75s forwards;
  }
  @keyframes active-menu-icon-bar03 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-10px) rotate(0);
    }
    100% {
      transform: translateY(-10px) rotate(-45deg);
    }
  }

  @media screen and (max-width: 415px) {
    .links {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    .nav-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
      width: 100%;
      padding: 1rem 1rem;
    }

    .right {
      background-color: #00051f;
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding: 2rem;
  
      a {
        text-decoration: none;
        color: #000723;
        font-size: 1.5rem;
        padding: 2px 0px;
        position: relative;
      }

    }

    .fullpage-menu {
      position: fixed;
      top: 0;
      right:0;
      // width: 100%;
      height:50vh;
      display: none;
      z-index: 3;
      margin-top:100px;
    }
    .menu-bg {
      height:50vh;
      // width: 100%;
      width:100vw;
      position: absolute;
      // left: 0;
      top: 0;
      right:0;
      display: grid;
      grid-template-columns:250px;
    }
  
    .menu-bg .right {
      height:50vh;
      width:100vw;
      border-radius: 60px 0px 0px 0px;
      background: #00D7FF;
      background-repeat: no-repeat;
      display: block;
      background-size: 32rem;
      // color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      padding: 4rem;
    }
  
  }

  @media screen and (max-width: 1200px) {
    .nav-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
      width: 100%;
      padding: 2rem 1rem;
    }

    .right {
      background-color: #00051f;
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding: 2rem;
  
      a {
        text-decoration: none;
        color: #000723;
        font-size: 2.5rem;
        padding: 2px 0px;
        position: relative;
      }

    }

    
    .fullpage-menu {
      position: fixed;
      top: 0;
      right:0;
      // width: 100%;
      width:100vw;
      // height: 250px;
      height:100vh;
      display: none;
      z-index: 3;
      margin-top:100px;
    }
    .menu-bg {
      // height: 250px;
      height:100vh;
      // width: 100%;
      width:100vw;
      position: absolute;
      // left: 0;
      top: 0;
      right:0;
      display: grid;
      grid-template-columns:250px;
    }
  
    .menu-bg .right {
      height:100vh;
      width:100vw;
      border-radius: 60px 0px 0px 0px;
      background: #00D7FF;
      background-repeat: no-repeat;
      display: block;
      background-size: 32rem;
      // color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      padding: 4rem;
    }
  
  }


  @media screen and (max-width: 1600px) and (min-width: 1200px) {
    .right {
      background-color: #00051f;
  
      a {
        text-decoration: none;
        color: #000723;
        font-size: 1.5rem;
        letter-spacing: 0.05em;
      }
      a {
        padding: 15px 20px;
        position: relative;
      }
    }
    .menu-bg .right {
      padding: 1rem;
    }
  }




`;

export default Navbar;
